<template>
  <transition>
    <esp-dx-data-grid :data-grid="metricsGrid" ref="metricsGrid" />
  </transition>
</template>

<script>
import {cloneObj, isSuccess} from '@/plugins/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm;

  export default {
    components: { EspDxDataGrid },
    props: {
      modalData: Object,
    },
    watch: {},
    data() {
      return {
        metricsGrid: {
          keyExpr: 'kpiPerformMetricsId',
          refName: 'metricsGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            cellHoverChanged: false,
            cellPrepared: false,
            checkDataBeforeSaving: false,
            initNewRow: false,
            rowClick: false,
            rowInserted: false,
            rowInserting: false,
            saving: false,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            excel: false,
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '성과관리지표',
              dataField: 'kpiPerformMetricsNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
            },
            {
              caption: '평가방식설명',
              dataField: 'description',
              alignment: 'center',
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
            },
            {
              caption: '자동여부',
              dataField: 'autoFl',
              alignment: 'center',
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                return rowData.autoFl == this.$_enums.common.stringUsedFlag.YES.value ? '자동' : '수동';
              },
              lookup: {
                dataSource: [
                  {
                    label: '자동',
                    value: 'Y',
                  },
                  {
                    label: '수동',
                    value: 'N',
                  },
                ],
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      async selectDataList() {
        const payload = {
          actionname: 'EWM_PERFORMANCE_METRICS_SELECT',
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.metricsGrid.dataSource = res.data.data;
        }
      },
      /** @description : 라이프사이클 created 호출되는 메서드 */
      createdData() {
        vm = this;

        this.selectDataList();
      },
      /** @description : 라이프사이클 mounted 호출되는 메서드 */
      mountedData() {
        this.$watch(
          () => {
            return this.$refs.metricsGrid.selectedRowsData;
          },
          val => {
            val = cloneObj(val);
            this.$emit('input', { val });
          },
        );
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountedData();
    },
  };
</script>
